import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { PaymentControllerService } from "@aparraga/ahoracrypto-api-sdk/sdk";
import { Observable, of, ReplaySubject } from "rxjs";
import { Order } from "../../domain/order";
import { PaymentGateway } from "../gateway";

@Injectable({
  providedIn: 'root'
})
export class PaypalGateway implements PaymentGateway {

  public gatewayId: string = 'paypal';

  public constructor(
    protected paymentControllerService: PaymentControllerService,
    @Inject(DOCUMENT) protected document: Document) {

  }

  public setupPayment(order: Order, paymentData: any): Observable<any> {

    return of(null);

  }

  public initPayment(order: Order, paymentData: any) {

    let returnValue: ReplaySubject<any> = new ReplaySubject();

    this.paymentControllerService.paymentControllerInitPayment(this.gatewayId, { order: order, data: paymentData }).subscribe(paymentResult => {
      if (paymentResult != null) {
        //redirect to the paypal url:
        this.document.defaultView.location.href = paymentResult[0];
      }
      else {
        returnValue.next("ERROR");
      }
    });

    return returnValue;

  }

  public completePayment(params): Observable<any> {

    return of(null);

  }


}
