import { Inject, Injectable, makeStateKey, StateKey, TransferState } from "@angular/core";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { CheckoutService } from "../checkout/checkout.service";
import { OrderService } from "../order/order.service";
import { IS_BROWSER } from "../utils/injectiontokens";
import { PaymentGateway } from "./gateway";
import { BankTransferGateway } from "./gateways/banktransfergateway";
import { PaypalGateway } from "./gateways/paypalgateway";
import { StripeBankTransferGateway } from "./gateways/stripebanktransfergateway";
import { TestGateway } from "./gateways/testgateway";
import { ZenGateway } from "./gateways/zengateway";
import { StripeCardGateway } from "./gateways/stripecardgateway";


@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  protected paidOrderKey: StateKey<any> = makeStateKey<any>("paidOrder");

  public _paidOrder;
  set paidOrder(value) {
    this._paidOrder = value;
    this.cache.set(this.paidOrderKey, value);
  }
  get paidOrder() { return this._paidOrder }

  protected paymentGateways: Map<string, PaymentGateway> = new Map();

  public constructor(protected checkoutService: CheckoutService,
                     protected cache: TransferState,
                     protected orderService: OrderService,
                     @Inject(IS_BROWSER) protected isBrowser: boolean,
                     protected zenGateway: ZenGateway,
                     protected stripeBankTransferGateway: StripeBankTransferGateway,
                     protected paypalGateway: PaypalGateway,
                     protected stripeCardGateway: StripeCardGateway,
                     protected bankTransferGateway: BankTransferGateway,
                     protected testGateway: TestGateway) {

    if(cache.hasKey(this.paidOrderKey)) {
      this._paidOrder = this.cache.get(this.paidOrderKey, null);
    }

    this.paymentGateways.set(this.paypalGateway.gatewayId, this.paypalGateway);
    this.paymentGateways.set(this.zenGateway.gatewayId, this.zenGateway);
    this.paymentGateways.set(this.bankTransferGateway.gatewayId, this.bankTransferGateway);
    this.paymentGateways.set(this.stripeBankTransferGateway.gatewayId, this.stripeBankTransferGateway);
    this.paymentGateways.set(this.stripeCardGateway.gatewayId, this.stripeCardGateway);
    this.paymentGateways.set(this.testGateway.gatewayId, this.testGateway);

  }

  public setupPayment(paymentGatewayId: string, paymentData: any): Observable<any> {
    this.paidOrder = null; //reset the payment whenever we initiate a new payment
    let paymentGateway: PaymentGateway = this.getPaymentGateway(paymentGatewayId);
    return paymentGateway.setupPayment(this.orderService.order, paymentData);
  }

  public initPayment(paymentGatewayId: string, paymentData: any): Observable<any> {
    this.paidOrder = null; //reset the payment whenever we initiate a new payment
    let paymentGateway: PaymentGateway = this.getPaymentGateway(paymentGatewayId);
    return paymentGateway.initPayment(this.orderService.order, paymentData);
  }

  public completePayment(paymentGatewayId: string, params) {
    let paymentGateway: PaymentGateway = this.getPaymentGateway(paymentGatewayId);
    return paymentGateway.completePayment(params).pipe(map(paymentResult => {
      if (paymentResult != null) {
        if(paymentResult != null && paymentResult.hasOwnProperty('paymentId') && paymentResult.paymentId != null) {
          this.paidOrder = paymentResult;
        }
      }
      return paymentResult;
    }));
  }

  public getPaymentGateway(paymentGatewayId: string) : PaymentGateway {
    if (!this.paymentGateways.has(paymentGatewayId)) {
      throw new Error(`Payment gateway ${paymentGatewayId} not found`);
    }
    return this.paymentGateways.get(paymentGatewayId);
  }




}
