import { Injectable } from "@angular/core";
import { PaymentControllerService } from "@aparraga/ahoracrypto-api-sdk/sdk";
import { OrderService } from "@aparraga/ahoracrypto-client-common/src/app/common/order/order.service";
import { Observable, ReplaySubject } from 'rxjs';
import { Order } from "../../domain/order";
import { JsLoaderService } from "../../jsloader/jsloader.service";
import { PaymentGateway } from "../gateway";

declare var ZenCheckout: any;

@Injectable({
  providedIn: 'root'
})
export class ZenGateway implements PaymentGateway {

  protected checkoutId: string;
  public gatewayId: string = 'zen';

  public constructor(protected paymentControllerService: PaymentControllerService,
                     protected orderService: OrderService,
                     protected jsLoaderService: JsLoaderService) {

  }

  public setupPayment(order: Order, paymentData: any): Observable<any> {
    let returnValue: ReplaySubject<any> = new ReplaySubject();
    this.paymentControllerService.paymentControllerSetupPayment(this.gatewayId, {order: order, data: paymentData}).subscribe(paymentResult => {
      if(paymentResult != null) {
        let paymentId = paymentResult[0];
        //update payment data at this point:
        this.orderService.order.paymentData = {paymentId: paymentId};
        if(paymentId != 'ERROR') {
          this.checkoutId = paymentId;
          returnValue.next(paymentId);
        }
        else {
          returnValue.next('ERROR');
        }
      }
      else {
        returnValue.next("ERROR");
      }
    });
    return returnValue;
  }

  public initPayment(order: Order, paymentData: any): Observable<any> {

    let returnValue: ReplaySubject<any> = new ReplaySubject();
    this.showPaymentPopup().subscribe(paymentResult => {
      if(paymentResult == 'CANCEL' || paymentResult == 'FAILURE') {
        returnValue.next({status: paymentResult});
      }
      else {
        returnValue.next({status: 'SUCCEEDED', paymentId: paymentResult});
      }
    });
    return returnValue;

  }

  public completePayment(params): Observable<any> {

    let returnValue: ReplaySubject<any> = new ReplaySubject();

    this.paymentControllerService.paymentControllerCompletePayment(this.gatewayId, params).subscribe(paymentResult => {
      if (paymentResult != null) {
        returnValue.next(paymentResult);
      }
    });

    return returnValue;

  }

  public showPaymentPopup(): Observable<any> {

    let paymentData: ReplaySubject<any> = new ReplaySubject();

    if(this.checkoutId != null) {

      this.jsLoaderService.loadJavascript("https://cdn-secure.zen.com/zen-checkout.1.0.0.js").then(() => {

        const openPopup = (checkoutId) => {
          const callbacks = {
            cancelCallback: () => {
              paymentData.next('CANCEL'); //todo
            },
            successCallback: () => {
              let data = {
                paymentId: checkoutId,
              };
              paymentData.next(data); //todo
            },
            failureCallback: () => {
              paymentData.next('FAILURE'); //todo
            }
          };

          const configParams = {
            "boldFont": "Roboto",
            "regularFont": "Roboto",
            "overlayBackgroundColor": "rgba(0,0,0,0.25)",
            "containerBackgroundColor": "#F9F9F9",
            "containerBorderRadius": "10px",
            "containerBorderColor": "1px solid #D3D4DC",
            "containerBoxShadow": "0px 4px 34px 0px rgba(0, 0, 0, 0.25)",
            "topBarBackgroundColor": "#FFFFFF",
            "topBarFontColor": "#222222",
            "topBarCloseIconColor": "#222222",
            "inputBackgroundColor": "#FFFFFF",
            "inputPrefixIconColor": "#b3b3b3",
            "inputErrorColor": "#ff0000",
            "inputFontColor": "#222222",
            "inputPlaceholderFontColor": "#a8a8a8",
            "inputBorderRadius": "3px",
            "inputBorderColor": "1px solid #d3d4dc",
            "inputBoxShadow": "unset",
            "buttonBackgroundColor": "#2EB875",
            "buttonFontColor": "#FFFFFF",
            "buttonBorderRadius": "100px",
            "buttonBorderColor": "1px solid #2EB875",
            "buttonBoxShadow": "unset",
            "fontAndIconColor": "#999999",
            "statusSuccessIconColor": "#2EB875",
            "statusFailureIconColor": "#FF0000",
            "statusPendingIconColor": "#2EB875",
            "statusTextColor": "#222222"
          };

          const checkout = new ZenCheckout(checkoutId, callbacks, configParams);
          checkout.paymentPopup();
        }

        openPopup(this.checkoutId);

      });
    }
    else {
      paymentData.error('No webwidget id');
    }

    return paymentData;

  }



}
