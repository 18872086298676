import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { PaymentControllerService } from "@aparraga/ahoracrypto-api-sdk/sdk";
import { Observable, of, ReplaySubject } from 'rxjs';
import { Order } from "../../domain/order";
import { PaymentGateway } from "../gateway";

@Injectable({
  providedIn: 'root'
})
export class StripeCardGateway implements PaymentGateway {

  public gatewayId: string = 'stripe-card';

  public constructor(protected paymentControllerService: PaymentControllerService,
    protected router: Router) {

  }

  public setupPayment(order: Order, paymentData: any): Observable<any> {

    return of(null);

  }

  public initPayment(order: Order, paymentData: any) {

    let returnValue: ReplaySubject<any> = new ReplaySubject();

    this.paymentControllerService.paymentControllerInitPayment(this.gatewayId, { order: order, data: paymentData }).subscribe(paymentResult => {
      if (paymentResult != null) {
        let paymentId = paymentResult[0];
        if (paymentId != 'ERROR') {
          returnValue.next({ status: 'SUCCEEDED', paymentId: paymentId });
        }
        else {
          returnValue.next('ERROR');
        }

      }
      else {
        returnValue.next("ERROR");
      }
    });

    return returnValue;

  }

  public completePayment(params): Observable<any> {

    let returnValue: ReplaySubject<any> = new ReplaySubject();

    this.paymentControllerService.paymentControllerCompletePayment(this.gatewayId, params).subscribe(paymentResult => {
      if (paymentResult != null) {
        returnValue.next(paymentResult);
      }
    });

    return returnValue;

  }

  public showPopup() {
    const popup = document.getElementById('stripe-card-modal');
    if (popup) {
      popup.classList.remove('hidden');
    }
  }



}
